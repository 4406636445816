import React from 'react';

class TestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            hovered: false
        }
    }
    componentDidMount = () => {
        if(this.props.color) {
            this.ref.style.backgroundColor = this.props.color;
        }
    }
    render = () => {
        return (
            <div 
                className={this.state.hovered ? 'marker' : this.props.type}
                ref={(marker) => {this.ref = marker}}
                onMouseEnter={()=>{this.setState({hovered:true})}}
                onMouseLeave={()=>{this.setState({hovered:false})}}
                onTouchEnd={()=>{this.setState({hovered:!this.state.hovered})}}
            >{(this.props.type === 'marker' || this.state.hovered) && this.props.text}
            </div>
        )
    }
}

export default TestComponent;