import React from 'react';
import Map from './Map/Map';
import InputManager from '../js/InputManager';

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: 'Cargando...',
            token: InputManager.getParameterByName('token'),
            center: JSON.parse(InputManager.getParameterByName('center')),
            useKey: InputManager.getParameterByName('usekey'),
            key: "",
            markers: [],
            info: false,
            zoom: InputManager.getParameterByName('zoom')
        }
    }

    componentDidMount = () => {
        if(this.state.useKey) {
            this.getApiKey();
        }
        this.getMarkers();
    };

    getMarkers = () => {
        const markersPromise = InputManager.dataGetter(this.state.token);
        markersPromise.then((data) => {
            const markers = data.data;
            this.setState({markers, message: null}) ;
        }).catch((e) => {
            this.setState({message: "Ocurrió un error: " + JSON.stringify(e)});
        });
    };

    getApiKey = () => {
        InputManager.apiKeyGetter(this.state.token)
            .then(response => {
                this.setState({key: response.data});
            }).catch(error => {
                this.setState({key: null, message: 'No api-key'});
            });
    }

    tooggleShow = () => {
        this.setState({
            info: !this.state.info
        })
    }

    render = () => {
        const center = {
            lat: parseFloat(this.state.center.lat),
            lng: parseFloat(this.state.center.lng)
        };
        return (
            <div>
                {this.state.message && this.state.message}
                <button className="button" onClick={this.tooggleShow}>{this.state.info ? 'Ocultar información' : 'Mostrar información'}</button>
                <Map
                    center={center}
                    markers={this.state.markers}
                    apikey={this.state.key}
                    type={this.state.info ? 'marker' : 'point'}
                    zoom={this.state.zoom ? parseInt(this.state.zoom) : 15}
                />
            </div>
        );
    }
} 

const viejo = () => {
    const rawData = InputManager.getParameterByName('data');
    const data = JSON.parse(rawData);
    const center = {
        lat: parseFloat(data.center.latitude),
        lng: parseFloat(data.center.longitude)
    };
    
    const markers = data.points.map( rawPoint => {
        const point = {
            ...rawPoint,
            lat: parseFloat(rawPoint.latitude),
            lng: parseFloat(rawPoint.longitude),
            color: rawPoint.color
        }
        return point;
    });
}    
