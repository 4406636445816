import React from 'react';
import GoogleMapReact from 'google-map-react';
import TestComponent from '../TestComponent/TestComponent';

export default class Map extends React.Component {

   static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 7,
    markers: [],
    token: "",
    apikey: "",
    type: 'point'
  };

  render = () => {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{key: this.props.apikey ? this.props.apikey : ""}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
        {
            this.props.markers.map(marker => {
                return(
                    <TestComponent
                      lat={marker.lat}
                      lng={marker.lng}
                      text={marker.title}
                      color={marker.color}
                      info={marker.info}
                      type={this.props.type}
                    />
                )
            })
        }
        </GoogleMapReact>
      </div>
    );
  }
}