import axios from 'axios';

const urlProduction = 'http://velneo.servinfor.es/oil';
const urlDevelopment = 'http://localhost:3000';
const useUrl = urlProduction;

export default class InputManager {
    static getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    static apiKeyGetter(token) {
        const url = `${useUrl}/api/v1/api-key`;
        return axios.get(url, {
            headers:{
                authorization: token
            }
        })
    }

    static dataGetter(token) {
        const url = `${useUrl}/api/v1/data`;
        return axios.get(url, { headers: { authorization: token } });
    }
}